import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuItems from './MenuItems';
import { Route, NavLink, Redirect } from 'react-router-dom';
import DASHContainer from '../DASHController/DASHContainer';
import LeadsContainer from '../LeadsContainer/LeadsContainer';
import AddLeadContainer from '../LeadsContainer/AddLead/AddLeadContainer';
import DealerLeadsImport from '../LeadsContainer/DealerLeadsImport';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ListItemText from '@material-ui/core/ListItemText';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EmailIcon from '@material-ui/icons/Email';
import DealerDepartments from '../../components/dealerAdmin/DealerDepartments';
import Link from '@mui/material/Link';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import StateAdminTable from '../stateAdmin/StateAdminTable';
import StateAdminForm from '../stateAdmin/StateAdminForm';
import Menu from '@material-ui/core/Menu';
import UserAdminTable from '../userAdmin/UserAdminTable';
import UserAdminForm from '../userAdmin/UserAdminForm';
import AdminManufacturers from '../userAdmin/AdminManufacturers';
import UserEditContainer from '../userAdmin/UserEditContainer';
import StatePermissionsMatrix from '../statePermissions/StatePermissionsMatrix';
import MessagingSettingsTable from '../messagingAdmin/MessagingSettingsTable';
import MessagingSettingsForm from '../messagingAdmin/MessagingSettingsForm';
import SlidesTable from '../slides/SlidesTable';
import SlidesForm from '../slides/SlidesForm';
import OfferForm from '../offers/OfferForm';
import { DealerSelect } from '../../shared/DealerSelect';
import { AuthContext } from '../../shared/context/AuthContext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DealerAdminTable from '../dealerAdmin/DealerAdminTable';
import DealerContainer from '../dealerAdmin/DealerContainer';
import Dashboard from '../dealerAdmin/Dashboard';
import VehicleLibraryMakes from '../VehicleLibrary/VehicleLibraryMakes';
import VehicleLibraryModels from '../VehicleLibrary/VehicleLibraryModels';
import VehicleLibraryVariants from '../VehicleLibrary/VehicleLibraryVariants';
import VehicleLibraryMakeForm from '../VehicleLibrary/Forms/VehicleLibraryMakeForm';
import VehicleLibraryModelForm from '../VehicleLibrary/Forms/VehicleLibraryModelForm';
import VehicleLibraryVariantForm from '../VehicleLibrary/Forms/VehicleLibraryVariantForm';
import ManageVehiclesContainer from '../ManageVehicles/ManageVehiclesContainer';
import BulkLeadActionsContainer from '../bulkLeadActions/BulkLeadActionsContainer';
import { useLocation } from 'react-router-dom';
import ExportContainer from '../export/ExportContainer';
import OfferContainer from '../offers/OfferContainer';
import DashLogo from '../../shared/assets/DashLogo.png';
import { DealerContext } from '../../shared/DealerContext';
import WebsiteTable from '../websites/WebsiteTable';
import WebsiteContainer from '../websites/WebsiteContainer';
import CategoryTable from '../categories/CategoryTable';
import config from '../../config';
import CategoryContainer from '../categories/CategoryContainer';
import BuildsTable from '../builds/BuildsTable';
import MotorgroupTable from '../motorgroups/MotorgroupTable';
import MotorgroupContainer from '../motorgroups/MotorgroupContainer';
import TemplateTable from '../templates/TemplateTable';
import TemplateContainer from '../templates/TemplateContainer';
import NoPermissionSnackbar from '../../shared/NoPermissionSnackbar';
import { TemplateProvider } from '../../shared/context/TemplateContext';
import TemplateFormsTable from '../TemplateForms/TemplateFormsTable';
import TemplateFormAddEdit from '../TemplateForms/TemplateFormAddEdit';
import {
  ROLE_ADMIN,
  PERMISSION_CONFIGURATOR,
  PERMISSION_VEHICLE_LIBRARY,
  PERMISSION_SLIDES,
  PERMISSION_OFFERS,
  PERMISSION_CATEGORIES,
  PERMISSION_MOTORGROUPS,
  PERMISSION_WEBSITES,
  PERMISSION_TEMPLATES,
  PERMISSION_DEALERS,
  PERMISSION_DEALER_DEPARTMENTS,
  PERMISSION_MANAGE_VEHICLES,
  PERMISSION_TEMPLATE_FORMS
} from '../../shared/Constants';
import { ChevronRight, Info } from '@material-ui/icons';

const drawerWidth = 241;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    background: 'white',
    paddingRight: 24
  },
  toolbarIcon: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '16px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),

    boxShadow: 'none',
    border: '1px solid rgba(171, 189, 211, 0.50)'
  },
  appBarShift: {
    height: '5rem',
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36,
    fill: 'grey'
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflowX: 'hidden',
    color: 'white',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6.5)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  dealerSelect: {
    minWidth: '200px',
    marginTop: '1rem'
  },
  logo: {
    maxWidth: 110
  },
  linkButton: {
    color: '#6f7276',
    textDecoration: 'none'
  },
  linkContainer: {
    display: 'inline-flex',
    height: '48px',
    padding: '13px 20px',
    alignItems: 'center',
    gap: '20px',
    flexShrink: 0,
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    borderRadius: '5px',
    border: '1px solid #012F56',
    background: '#012F56'
  },
  rateContainer: {
    color: 'white !important',
    width: '100px',
    marginTop: '17px',
    marginRight: '15px',
    '&$MuiFormLabel-root ': {
      marginRight: '40px',
      width: '100px !important',
      color: 'white !important',
      textAlign: 'center',
      display: 'flex'
    },
    '&$notchedOutline': {
      marginRight: '40px',
      width: '100px !important',
      color: 'white',
      textAlign: 'center',
      display: 'flex'
    }
  }
}));

export default function DashboardContainer() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout, role, permissions, userSession } = useContext(AuthContext);
  const permissionsArr = userSession.permissions ?? permissions;
  const userRole = userSession ? userSession?.role : null;
  const [showBack, setShowBack] = React.useState(false);
  let { ...dealerContext } = useContext(DealerContext);

  const [disableDealerSelect, setDisableDealerSelect] = useState(false);

  let location = useLocation();

  useEffect(() => {
    if (location) {
      const path = location.pathname;
      setShowBack(path.includes(ROLE_ADMIN.toLowerCase()) && (path.includes('add') || path.includes('edit')));
      setDisableDealerSelect(
        path.includes('website/edit') || path.includes('website/add') || path.includes(PERMISSION_DEALERS) || path.includes('dealer')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSettingsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const hasRole = (roleToCheck) => {
    if (!role) {
      return false;
    }

    return role === roleToCheck;
  };

  const Typography = ({ children }) => {
    const typographyStyle = {
      margin: '0px 20px 0 20px',
      color: 'rgb(30, 30, 30)',
      fontFamily: '"SF Pro Text", sans-serif',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal'
    };

    return <p style={typographyStyle}>{children}</p>;
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <ChevronRight onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)} />

            <div className={classes.title}>
              <img src={DashLogo} alt='logo' className={classes.logo} />
            </div>

            {dealerContext.dealerId && (
              <div
                className={classes.linkContainer}
                onClick={() => window.open(`https://${dealerContext?.selectedMotorGroup?.websiteUrl}`)}
              >
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'inherit'
                  }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Go to Website
                </Link>
              </div>
            )}

            <Typography>
              Global Interest Rate: {`${dealerContext.dealerId !== '' ? dealerContext.selectedMotorGroup?.globalInterestRate : '0.00'} %`}
            </Typography>

            <DealerSelect disabled={disableDealerSelect} secondary className={classes.dealerSelect} />
            {hasRole(ROLE_ADMIN) && (
              <IconButton onClick={handleSettingsOpen} color='inherit'>
                <SettingsIcon style={{ color: '#012F56' }} />
              </IconButton>
            )}

            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleSettingsClose}>
              {hasRole(ROLE_ADMIN) && (
                <>
                  <ListItem button onClick={handleSettingsClose} component={NavLink} to='/admin/users'>
                    <ListItemIcon>
                      <PeopleAltIcon />
                    </ListItemIcon>
                    <ListItemText primary='Users' />
                  </ListItem>

                  <ListItem button onClick={handleSettingsClose} component={NavLink} to='/admin/roles'>
                    <ListItemIcon>
                      <AssignmentIndIcon />
                    </ListItemIcon>
                    <ListItemText primary='Roles' />
                  </ListItem>
                  <ListItem button onClick={handleSettingsClose} component={NavLink} to='/admin/export'>
                    <ListItemIcon>
                      <ImportExportIcon />
                    </ListItemIcon>
                    <ListItemText primary='Export Leads' />
                  </ListItem>

                  <ListItem button onClick={handleSettingsClose} component={NavLink} to='/admin/Manufacturers'>
                    <ListItemIcon>
                      <PrecisionManufacturingIcon />
                    </ListItemIcon>
                    <ListItemText primary='Manufacturers' />
                  </ListItem>

                  <ListItem button onClick={handleSettingsClose} component={NavLink} to='/departments'>
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText primary='Dealer Departments' />
                  </ListItem>
                  <ListItem button onClick={handleSettingsClose} component={NavLink} to='/admin/messaging'>
                    <ListItemIcon>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText primary='Email & SMS Wording' />
                  </ListItem>

                  <ListItem button>
                    <ListItemIcon>
                      <Info />
                    </ListItemIcon>
                    <ListItemText primary={`Version: v${config.buildNumber}`} />
                  </ListItem>
                </>
              )}
            </Menu>
            <IconButton onClick={logout} color='inherit'>
              <Tooltip title='Logout' placement='bottom'>
                <ExitToAppIcon style={{ color: '#012F56' }} />
              </Tooltip>
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          variant='permanent'
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <Divider />
          <List>
            <MenuItems />
          </List>
          <Divider />
        </Drawer>
        {userRole !== null && permissionsArr.length > 0 ? (
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
              <Grid container spacing={3}>
                {/* Routes*/}
                <Grid item xs={12}>
                  <Route
                    exact
                    path='/'
                    render={() => {
                      return <Redirect to='/dashboard' />;
                    }}
                  />
                  <Route
                    path='/DASH/:step?/:modelId?/:dealerModelId?/:dealerVariantId?'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_CONFIGURATOR) ? DASHContainer : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/leads/:state?'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? LeadsContainer : NoPermissionSnackbar}
                  />
                  <Route
                    path='/admin/export'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? ExportContainer : NoPermissionSnackbar}
                  />
                  <Route
                    path='/addlead'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? AddLeadContainer : NoPermissionSnackbar}
                  />
                  <Route
                    path='/importLeads/dealer/:id'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? DealerLeadsImport : NoPermissionSnackbar}
                  />

                  <Route
                    path='/departments'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_DEALER_DEPARTMENTS)
                        ? DealerDepartments
                        : NoPermissionSnackbar
                    }
                  />

                  <Route
                    exact
                    path='/admin/states'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? StateAdminTable : NoPermissionSnackbar}
                  />
                  <Route
                    path='/admin/states/add'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? StateAdminForm : NoPermissionSnackbar}
                  />
                  <Route
                    path='/admin/states/edit/:id?'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? StateAdminForm : NoPermissionSnackbar}
                  />
                  <Route
                    exact
                    path='/admin/users'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? UserAdminTable : NoPermissionSnackbar}
                  />
                  <Route
                    path='/admin/users/add'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? UserAdminForm : NoPermissionSnackbar}
                  />
                  <Route
                    path='/admin/users/edit/:id?'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? UserEditContainer : NoPermissionSnackbar}
                  />
                  <Route
                    path='/admin/roles'
                    component={
                      userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? StatePermissionsMatrix : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/admin/manufacturers'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? AdminManufacturers : NoPermissionSnackbar}
                  />
                  <Route
                    exact
                    path='/admin/messaging'
                    component={
                      userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? MessagingSettingsTable : NoPermissionSnackbar
                    }
                  />

                  <Route
                    exact
                    path='/slides'
                    component={permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_SLIDES) ? SlidesTable : NoPermissionSnackbar}
                  />
                  <Route
                    exact
                    path='/slides/add'
                    component={permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_SLIDES) ? SlidesForm : NoPermissionSnackbar}
                  />
                  <Route
                    exact
                    path='/slides/edit/:id?'
                    component={permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_SLIDES) ? SlidesForm : NoPermissionSnackbar}
                  />

                  <Route
                    path='/admin/messaging/add'
                    component={
                      userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? MessagingSettingsForm : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/admin/messaging/edit/:id?'
                    component={
                      userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? MessagingSettingsForm : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/admin/leads/bulkactions'
                    component={
                      userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? BulkLeadActionsContainer : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/admin/dealer'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? DealerAdminTable : NoPermissionSnackbar}
                  />
                  <Route
                    path='/admin/dealers/add'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? DealerContainer : NoPermissionSnackbar}
                  />
                  <Route
                    path='/admin/dealers/edit/:id?'
                    component={userRole && userRole?.toLowerCase() === ROLE_ADMIN.toLowerCase() ? DealerContainer : NoPermissionSnackbar}
                  />

                  <Route
                    exact
                    path='/websites'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_WEBSITES) ? WebsiteTable : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/websites/add'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_WEBSITES) ? WebsiteContainer : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/websites/edit/:id?'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_WEBSITES) ? WebsiteContainer : NoPermissionSnackbar
                    }
                  />

                  <TemplateProvider>
                    <Route
                      exact
                      path='/templates'
                      component={
                        permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_TEMPLATES) ? TemplateTable : NoPermissionSnackbar
                      }
                    />
                    <Route
                      exact
                      path='/templates/add'
                      component={
                        permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_TEMPLATES) ? TemplateContainer : NoPermissionSnackbar
                      }
                    />
                    <Route
                      exact
                      path='/templates/edit/:id?'
                      component={
                        permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_TEMPLATES) ? TemplateContainer : NoPermissionSnackbar
                      }
                    />
                  </TemplateProvider>

                  <Route
                    exact
                    path='/categories'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_CATEGORIES) ? CategoryTable : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/templateForms'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_TEMPLATE_FORMS)
                        ? TemplateFormsTable
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/templateForms/add'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_TEMPLATE_FORMS)
                        ? TemplateFormAddEdit
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/templateForms/edit/:id'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_TEMPLATE_FORMS)
                        ? TemplateFormAddEdit
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/categories/add'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_CATEGORIES) ? CategoryContainer : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/categories/edit/:id?'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_CATEGORIES) ? CategoryContainer : NoPermissionSnackbar
                    }
                  />

                  <Route
                    exact
                    path='/motorgroups'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_MOTORGROUPS) ? MotorgroupTable : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/motorgroups/add'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_MOTORGROUPS)
                        ? MotorgroupContainer
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/motorgroups/edit/:id?'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_MOTORGROUPS)
                        ? MotorgroupContainer
                        : NoPermissionSnackbar
                    }
                  />

                  <Route
                    exact
                    path='/builds'
                    component={permissionsArr.some((o) => o.name.toLowerCase() === 'builds') ? BuildsTable : NoPermissionSnackbar}
                  />

                  <Route
                    exact
                    path='/dealers'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_DEALERS) ? DealerAdminTable : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/dealers/edit/:id?'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_DEALERS) ? DealerContainer : NoPermissionSnackbar
                    }
                  />

                  <Route exact path='/dashboard' component={Dashboard} />
                  <Route
                    exact
                    path='/offers'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_OFFERS) ? OfferContainer : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/offers/add'
                    component={permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_OFFERS) ? OfferForm : NoPermissionSnackbar}
                  />
                  <Route
                    path='/offers/edit/:id?'
                    component={permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_OFFERS) ? OfferForm : NoPermissionSnackbar}
                  />
                  <Route
                    exact
                    path='/VehicleLibrary/Makes'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_VEHICLE_LIBRARY)
                        ? VehicleLibraryMakes
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/VehicleLibrary/Make/:id/:action?'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_VEHICLE_LIBRARY)
                        ? VehicleLibraryMakeForm
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/VehicleLibrary/Makes/:makeId/Models'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_VEHICLE_LIBRARY)
                        ? VehicleLibraryModels
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/VehicleLibrary/Makes/:makeId/Model/:id/:action?'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_VEHICLE_LIBRARY)
                        ? VehicleLibraryModelForm
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    exact
                    path='/VehicleLibrary/Makes/:makeId/Models/:modelId/Variants'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_VEHICLE_LIBRARY)
                        ? VehicleLibraryVariants
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/VehicleLibrary/Makes/:makeId/Models/:modelId/Variant/:id/:action?'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_VEHICLE_LIBRARY)
                        ? VehicleLibraryVariantForm
                        : NoPermissionSnackbar
                    }
                  />
                  <Route
                    path='/ManageVehicles'
                    component={
                      permissionsArr.some((o) => o.name.toLowerCase() === PERMISSION_MANAGE_VEHICLES)
                        ? ManageVehiclesContainer
                        : NoPermissionSnackbar
                    }
                  />
                </Grid>
              </Grid>
            </Container>
          </main>
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
}
