import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  Divider,
  Paper,
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { BASE_URI } from '../../shared/Constants';
import { useSnackbar } from 'notistack';

import { LoadingContext } from '../../shared/context/loadingContext';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
  title: {
    background: '#bdbfc1',
    color: 'white',
    padding: '10px'
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

export default function PreownedFinance() {
  const initialValues = {
    depositPercentage: 0,
    interestRate: 0,
    financeTerm: 24,
    balloonPercentage: 0,
    initiationFee: 0,
    serviceFee: 0,
  };

  const { id } = useParams();
  const [formFields, setFormFields] = useState(initialValues);
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const onSubmit = (values) => {
    showLoading();
    values.dealerId = id;
    axios
      .put(`${BASE_URI}/dealerPreownedFinance/${id}`, values)
      .then(() => {
        enqueueSnackbar('Updated successfully', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Update failed: ' + error.response.data, { variant: 'error' });
      })
      .finally(() => {
        hideLoading();
      });
  };

  useEffect(() => {
    const getPreOwnedFinance = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/dealerPreownedFinance/${id}`);
        setFormFields(result.data);
      } catch (error) {
        console.error(error);
      }
    };

    getPreOwnedFinance();
  }, [id]);

  return (
    <Paper className={classes.paper}>
      <Formik
        initialValues={formFields}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={Yup.object().shape({
          depositPercentage: Yup.number().required('Deposit Percentage is required'),
          interestRate: Yup.number().required('Interest Rate is required'),
          financeTerm: Yup.number().required('Finance term is required'),
          balloonPercentage: Yup.number().required('Balloon Percentage is required'),
          initiationFee: Yup.number().required('Initiation Fee is required'),
          serviceFee: Yup.number().required('Service Fee is required')
        })}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={4} className={classes.stepPadding} direction="column">
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Pre-owned Finance
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  name="depositPercentage"
                  as={TextField}
                  label="Deposit Percentage (%)"
                  fullWidth
                  type="number"
                  error={touched.depositPercentage && !!errors.depositPercentage}
                  helperText={touched.depositPercentage && errors.depositPercentage}
                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault();  
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  name="interestRate"
                  as={TextField}
                  label="Interest Rate (%)"
                  fullWidth
                  type="number"
                  error={touched.interestRate && !!errors.interestRate}
                  helperText={touched.interestRate && errors.interestRate}
                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault();  
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  name="financeTerm"
                  as={TextField}
                  label="Finance Term"
                  type="number"
                  fullWidth
                  error={touched.financeTerm && !!errors.financeTerm}
                  helperText={touched.financeTerm && errors.financeTerm}
                  inputProps={{
                    min: 1, 
                    step: 1  
                  }}

                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault();  
                    }
                  }}
                />
              </Grid>


              <Grid item xs={12} md={6}>
                <Field
                  name="balloonPercentage"
                  as={TextField}
                  label="Balloon Percentage (%)"
                  fullWidth
                  type="number"
                  error={touched.balloonPercentage && !!errors.balloonPercentage}
                  helperText={touched.balloonPercentage && errors.balloonPercentage}
                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault();  
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  name="initiationFee"
                  as={TextField}
                  label="Initiation Fee"
                  fullWidth
                  type="number"
                  error={touched.initiationFee && !!errors.initiationFee}
                  helperText={touched.initiationFee && errors.initiationFee}
                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault();  
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Field
                  name="serviceFee"
                  as={TextField}
                  label="Service Fee"
                  fullWidth
                  type="number"
                  error={touched.serviceFee && !!errors.serviceFee}
                  helperText={touched.serviceFee && errors.serviceFee}
                  onKeyDown={(e) => {
                    if (e.key === '-' || e.key === 'e') {
                      e.preventDefault();  
                    }
                  }}
                />
              </Grid>

              <Grid item container justifyContent='flex-end' xs={12}>
                  <Button type='submit' variant='contained' color='primary'>
                    Save
                  </Button>
                </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
