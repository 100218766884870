import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  TextField,
  Button,
  Box,
  Divider,
  Paper,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Chip,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { BASE_URI } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
  featureInputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    background: '#bdbfc1',
    color: 'white',
    padding: '10px'
  }
}));

export default function DealerImportersForm({ history, props }) {
  const initialValues = {
    importerMappingId: 0,
    importType: 0,
    foreignDealerId: '',
    selectedMappings: []
  };

  const { id } = useParams();
  const [formFields, setFormFields] = useState(initialValues);
  const [importerArr, setImporterArr] = useState([]);
  const [importerMappingArr, setImporterMappingArr] = useState([]);
  const [selectedImporter, setSelectedImporter] = useState(null);
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [dealerIds, setDealerIds] = useState([]);
  const classes = useStyles();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };

  const onSubmit = (foreignDealerId, importerId, setFieldValue) => {
    showLoading();
    axios
      .put(`${BASE_URI}/importer/${id}`, {
        dealerId: parseInt(id),
        selectedMappings: foreignDealerId === '' ? dealerIds : [],
        foreignDealerId: foreignDealerId || '',
        importerId: importerId
      })
      .then((result) => {
        enqueueSnackbar('Updated importer', { variant: 'success' });
        setFieldValue('foreignDealerId', '');
        getImporterMapping(importerId);
      })
      .catch((error) => {
        enqueueSnackbar('Update failed:' + error.response.data, { variant: 'error' });
      }).finally(() => {
        hideLoading();
      });
  };

  useEffect(() => {
    const getImporters = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/lookup/importers`);
        setImporterArr(result.data);
      } catch (error) {
        // addAlert('Unable to load vehicle categories')
        // history.push('..')
      }
    };

    getImporters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImporterSelect = (event, setFieldValue) => {
    const selectedImporterId = event.target.value;
    setDealerIds([]);
    setFieldValue('importer', selectedImporterId);
    setSelectedImporter(selectedImporterId);
    getImporterMapping(selectedImporterId);   
  };
  
  const getImporterMapping = async (importerId) => {
    try {
      const result = await axios.get(`${BASE_URI}/importer`, {
        params: {
          dealerId: id,   
          importerId: importerId  
        }
      });
  
      if (result.data) {
        setImporterMappingArr(result.data);   
        setDealerIds([]);   
        let dealerIdz = [];
        result.data.forEach((f_dealer) => {
          dealerIdz.push(f_dealer.importerMappingId);   
        });
        setDealerIds(dealerIdz);  
      }
    } catch (error) {
      enqueueSnackbar(`Unable to retrieve mappings`, { variant: 'error' });   
    }
  };
  
  return (
    <Paper className={classes.paper}>
      <Formik
        initialValues={formFields}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          dealerId: Yup.string().required('Dealership Name is required'),
          selectedMappings: Yup.array().required('Please select at least one mapping')
        })}
      >
        {(props) => {
          const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

          return (
            <Form>
              <Grid container spacing={4} className={classes.stepPadding} direction='column'>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom>
                      STOCK IMPORTERS
                    </Typography>
                  </Grid>
                  <div>
                    <InputLabel htmlFor='importer'>Select Importer</InputLabel>
                    <Select
                      id='importer'
                      fullWidth
                      value={selectedImporter || ''}
                      required
                      onChange={(e) => handleImporterSelect(e, setFieldValue)}
                      input={<OutlinedInput id='importer' label='Importer' />}
                    >
                      {importerArr.map((importerOption) => (
                        <MenuItem key={importerOption.importerId} value={importerOption.importerId}>
                          {importerOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <TextField
                        fullWidth
                        name='foreignDealerId'
                        id='foreignDealerId'
                        label='Foreign Dealer ID'
                        value={values.foreignDealerId}
                        helperText={errors.foreignDealerId && touched.foreignDealerId ? errors.foreignDealerId : ''}
                        error={errors.foreignDealerId && touched.foreignDealerId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                  {selectedImporter && (
                    <>
                    <Box mt={2} mb={2}>
                      <InputLabel htmlFor='mapping'>Configure mapping</InputLabel>
                      <Select
                        id='mapping'
                        multiple
                        fullWidth
                        value={dealerIds}
                        required
                        disabled={importerMappingArr.length==0}
                        error={!!(errors.selectedMappings && touched.selectedMappings)}
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue('selectedMappings', value);
                          setDealerIds(value); 
                        }}
                        input={<OutlinedInput id='importer' label='Importer' />}
                        renderValue={(selected) => {
                          const selectedArray = Array.isArray(selected) ? selected : [selected]; 
                          const selectedLabels = selectedArray.map((value) => {
                            const label = importerMappingArr.find((imp) => imp.importerMappingId === value)?.foreignDealerId;
                            return label ? <Chip key={value} label={label} /> : null;
                          });
                          return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>{selectedLabels}</Box>;
                        }}
                        MenuProps={MenuProps}
                      >
                        {importerMappingArr.map((option) => (
                          <MenuItem key={option.foreignDealerId} value={option.importerMappingId}>
                            <Checkbox checked={dealerIds.indexOf(option.importerMappingId) > -1} />
                            <ListItemText primary={option.foreignDealerId} />
                          </MenuItem>
                        ))}
                      </Select>
                      </Box>
                      

                      <Button variant="contained" color="primary" onClick={() => onSubmit(values.foreignDealerId, selectedImporter, setFieldValue)}>
                        Save Mappings
                      </Button>
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}
