import React, { useState, useEffect, useContext } from 'react';
import { Add as AddIcon, Edit as EditIcon, DeleteOutlined as DeleteIcon, Save as SaveIcon, Close as CancelIcon } from '@mui/icons-material';
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import { DEFAULT_PAGE_SIZE, BASE_URI } from '../../../shared/Constants';
import DealerMediaFilter from './DealerMediaFilter';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { LoadingContext } from '../../../shared/context/loadingContext';

export default function DealerMedia() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    page: page
  });
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('medianame');
  const [searchString, setSearchString] = useState('');
  const [fileBase64, setFileBase64] = useState(null);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { id } = useParams();
  const { loading, media, total } = DealerMediaFilter(searchString, page, pageSize, order, orderBy, id);

  const { enqueueSnackbar } = useSnackbar();

  const CustomLoader = styled('div')({
    marginTop: '8%',
    marginLeft: '40%',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
  });

  const CustomLoadingOverlay = () => {
    return (
      <CustomLoader>
        <CircularProgress />
      </CustomLoader>
    );
  }

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const [selectedFiles, setSelectedFiles] = useState({});
  const setSelectedFile = (id, file) => {
    setSelectedFiles(prevState => ({ ...prevState, [id]: file }));
  };

  useEffect(() => {
    setRows(media || []);
  }, [media]);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    deleteDealerMedia(id);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    editDealerMedia(updatedRow.id, updatedRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const EditToolbar = (props) => {
    const { setRows, setRowModesModel } = props;
    const handleClickAddMedia = async () => {
      try {
        let fields = { dealerId: id };
        const dm = await createDealerMedia(fields);
        let newDealerMediaId = dm?.data?.id;
        if (newDealerMediaId) {
          setRows((oldRows) => [...oldRows, { id: newDealerMediaId, pageUrl: '', attachment: '', isNew: true }]);
          setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'pageUrl' }
          }));
        }
      } catch (error) {
        enqueueSnackbar(error)
        console.error('Error:', error);
      }
    };

    return (
      <GridToolbarContainer>
        <Button color='primary' startIcon={<AddIcon />} onClick={handleClickAddMedia}>
          Add Media
        </Button>
      </GridToolbarContainer>
    );
  };

  const FileUploadCell = (params) => {
    const { id } = params;
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      setSelectedFile(id, file);

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          setFileBase64(base64String);
        };
        reader.readAsDataURL(file);
      }
    };

    const disableBtn = rowModesModel[id]?.mode !== GridRowModes.Edit;
    const existingFileName = rows.find(r => r.id === id)?.attachment?.split("_").slice(-1)[0].replace(/_/g, "-");

    return (
      <div>
      <Input
        accept="*"
        id={`file-upload-${params.id}`}
        type="file"
        onChange={handleFileUpload}
        disabled={disableBtn}
        style={{ display: 'none' }}
      />
      <label htmlFor={`file-upload-${params.id}`}>
        <Button variant="contained" component="span" disabled={disableBtn}>
          Upload
        </Button>
      </label>
      {selectedFiles[id] ? selectedFiles[id]?.name : existingFileName}
    </div>
    );
  };

  const columns = [
    { field: 'pageUrl', headerName: 'Page URL', width: 500, editable: true },

    {
      field: 'attachment',
      headerName: 'Attachment',
      width: 600,
      renderCell: (params) => <FileUploadCell {...params}  />
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />
        ];
      }
    }
  ];

  const editDealerMedia = async (id, fields) => {
    try {
      showLoading();
      fields.attachment = fileBase64 ?? fields.attachment;
      fields.fileName = selectedFiles[id]?.name.replace(/_/g, '-');
      await axios.put(`${BASE_URI}/DealerMedia/${id}`, fields).then((d) => {
        enqueueSnackbar(d.data, { variant: 'success' });
        setSearchString(id);
        hideLoading();
      });
    } catch (error) {
      enqueueSnackbar('error', { variant: 'error' });
      hideLoading();
    }
  };

  const deleteDealerMedia = async (id, fields) => {
    try {
      showLoading();
      await axios.delete(`${BASE_URI}/DealerMedia/${id}`, fields).then((d) => {
        enqueueSnackbar('deleted successfully', { variant: 'success' });
        hideLoading();
      });
    } catch (error) {
      enqueueSnackbar('error', { variant: 'error' });
      hideLoading();
    }
  };

  const createDealerMedia = async (fields) => {
    try {
      showLoading();
      await axios.post(`${BASE_URI}/DealerMedia`, fields).then((d) => {
        setSearchString(d?.data?.id);
        enqueueSnackbar('successfully added', { variant: 'success' });
        hideLoading();
      });
    } catch (error) {
      enqueueSnackbar('error', { variant: 'error' });
      hideLoading();
    }
  };

  return (
    <Box
      sx={{
        height: 800,
        width: '100%',
        '& .actions': {
          color: 'text.secondary'
        },
        '& .textPrimary': {
          color: 'text.primary'
        }
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode='row'
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        rowHeight={100}
        {...media}
        rowCount={total}
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        paginationMode='server'
        onPaginationModelChange={setPaginationModel}
        loading={loading}
        slots={{
          toolbar: EditToolbar,
          loadingOverlay: CustomLoadingOverlay,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel }
        }}
      />
    </Box>
  );
}
