import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, TextField, Button, Box, Toolbar } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { BASE_URI } from '../../shared/Constants';
import * as Yup from 'yup';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext';
import TextMaskCustom from '../../shared/TextMask';
import Divider from '@material-ui/core/Divider';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns/build/index';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },

  tr: {
    padding: '5px 30px'
  },
  th: {
    padding: '5px 10px',
    textAlign: 'center'
  },
  td: {
    padding: '2px 10px'
  },
  tdCenter: {
    padding: '2px 10px',
    textAlign: 'center'
  },
  textArea: {
    fontFamily: 'monospace',
    padding: '10px',
    width: '100%',
    height: '200px !important',
    fontSize: '12px',
    overflowY: 'scroll',
    whiteSpace: 'pre-wrap',
    backgroundColor: '#f4f4f4',
    border: '1px solid #ccc',
    borderRadius: '5px'
  }
}));

export default function MotorgroupForm() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [isAddMode, setIsAddMode] = useState(!id);
  const theme = useTheme();
  const mountedRef = useRef(true);
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);

  const initialValues = {
    name: '',
    phoneNumber: '',
    email: '',
    logo: ''
  };
  const [formFields, setFormFields] = useState(initialValues);

  useEffect(() => {
    const getMotorgroup = async () => {
      try {
        showLoading();
        const result = await axios.get(`${BASE_URI}/Motorgroups/${id}`);
        let formData = result.data;
        formData.isAddMode = isAddMode;

        setFormFields({
          ...formData
        });
      } catch (error) {
        enqueueSnackbar('Unable to get motorgroup details', { variant: 'error' });
        hideLoading();
      }
    };

    if (!isAddMode) {
      getMotorgroup().then(() => {
        hideLoading();
        if (!mountedRef.current) return null;
      });
    }

    return () => {
      mountedRef.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getErrorMessage(axiosError, defaultMsg) {
    let errorTitle = defaultMsg;
    let errorDetails = 'No additional details are available';
    if (axiosError?.request?.message || axiosError?.request?.statusText) {
      errorTitle = 'Failed to send the request';
      errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;
    } else if (axiosError?.response?.data) {
      errorTitle = axiosError?.response?.data?.title ?? errorTitle;
      errorDetails = axiosError?.response?.data?.details ?? errorDetails;
    }
    return `${errorTitle}: ${errorDetails}`;
  }

  const editMotorgroup = async (id, fields, setSubmitting, resetForm) => {
    try {
      const result = await axios.put(`${BASE_URI}/Motorgroups/${id}`, fields);
      enqueueSnackbar('Successfully saved the motorgroup.', { variant: 'success' });
      history.push('/motorgroups');
      return result;
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'Failed to save the motorgroup'), { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  const createMotorGroup = async (fields, setSubmitting, resetForm) => {
    try {
      const result = await axios.post(` ${BASE_URI}/Motorgroups`, {
        ...fields
      });

      enqueueSnackbar('Successfully saved the motorgroup.', { variant: 'success' });

      setIsAddMode(false);
      history.push('/motorgroups');

      return result;
    } catch (error) {
      enqueueSnackbar(`Failed to save motorgroup: ${error.message}`, { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    showLoading();

    const logo = fields.logo;
    fields.logo = logo?.image || logo;
    fields.fileName = logo?.fileName;
    if (isAddMode) {
      createMotorGroup(fields, setSubmitting, resetForm);
    } else {
      editMotorgroup(id, fields, setSubmitting, resetForm);
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <Formik initialValues={formFields} onSubmit={onSubmit} enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required'),
            email: Yup.string().required("email is required.").nullable()
          })}
          >
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

              return (
                <Form noValidate>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid spacing={4} container direction='row'>
                      <Grid item spacing={4} container xs={12}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            name='name'
                            id='name'
                            required
                            label='Name'
                            value={values.name}
                            type='text'
                            helperText={errors.name && touched.name ? errors.name : ''}
                            error={errors.name && touched.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          <TextField
                            fullWidth
                            name='email'
                            id='email'
                            required
                            label='Email'
                            value={values.email}
                            type='email'
                            helperText={errors.email && touched.email ? errors.email : ''}
                            error={errors.email && touched.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <TextField
                            fullWidth
                            label='Phone number'
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.phoneNumber}
                            helperText={errors.phoneNumber ? 'Please enter phone number' : ''}
                            name='phoneNumber'
                            id='phoneNumber'
                            InputProps={{
                              inputComponent: TextMaskCustom
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ImageUploadInput title='Motorgroup Image' fieldName={'logo'} error={errors.logo && touched.logo} />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                      </Grid>

                      <Grid item container justify='flex-end' xs={12}>
                        <Box mr={3}>
                          <Button
                            onClick={() => {
                              handleReset();
                              history.push('/motorgroups');
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                        <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
}
