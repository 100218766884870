import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    btnTxt: {
        color: 'white !important',
        outline: '0 !important'
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        zIndex: '1',
        top: 0
    },
    errorText: {
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: 400,
        lineHeight: '1.66',
        letterSpacing: '0.03333em',
        color: '#f44336'
    },
    dropzone: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        minHeight: '250px',
        justifyContent: 'center',
        padding: '3rem',
        outline: '0 !important'
    },
    container: {
        position: 'relative',
        minHeight: '170px',
        height: '100%',
        width: '100%',
        // maxWidth: props => props ? '150px': ''
    },

    modelPageContainer: {
        width: '72%',
        position: 'relative',
        minHeight: '170px',
        height: '100%',
    }
})

const dropzoneMessage = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

const deleteButton = {
    backgroundColor: '#d4411e',
    borderRadius: 0,
    position: 'absolute',
    zIndex: '100',
    right: 10,
    top: 10,
    outline: '0 !important'
}

export default function ImageUploader({ title, image, id, onDropFile, clear, error, className, required, small, disabled, errorMsg, isModelPage }) {
    const [files, setFiles] = useState([]);
    const [isDragAccept, setDragAccept] = useState(false)
    const classes = useStyles(small);
    const [loaded, setLoaded] = useState(null)
    const { getRootProps, getInputProps
    } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setDragAccept(true)
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            },)));
            onDropFile(Object.assign(acceptedFiles[0]), id)
        }
    });

    const clearImage = () => {
        clear(id)
        setDragAccept(false);
        setFiles([])
    }

    const thumbs = files.map((file, index) => (
        <GridListTile
          key={index}
          style={  { width: '100%', minHeight: '170px', height: '100%' } }
        >
          <img
            src={file.preview}
            style={ { width: '100%', height: '100%', objectFit: 'cover', borderRadius:'12px' }  } 
            onLoad={() => setLoaded(false)}
            alt={file.name}
          />
          {!disabled && (
            <IconButton
              aria-label="delete"
              classes={{ label: classes.btnTxt }}
              onClick={clearImage}
              color="primary"
              style={deleteButton}
            >
              <DeleteIcon size="small" />
            </IconButton>
          )}
        </GridListTile>
      ));

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    useEffect(() => {
        if (image) {
            setLoaded(true)
            setDragAccept(true);
            let reader = new FileReader();
            if (image.preview && image instanceof File) {
                reader.readAsDataURL(image);
                reader.onload = function (e) {
                    setFiles([{ preview: e.target.result }])
                };
            } else {
                setFiles([{ preview: image.image ? image.image : image.src ? image.src : image, name: id }])
            }
        } else {
            clearImage()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])

    return (
        <section className={className} style={{ minHeight: '250px' }}>
            <Typography variant="body2" color="textSecondary" gutterBottom component="p">
                {title} {required && ' *'}
            </Typography>
            <div className={ isModelPage ? classes.modelPageContainer: classes.container} style={{ minHeight: '250px'  }}>
                <div className={classes.dropzone}
                    style={files.length === 0 ? { border: '2px dashed lightgrey', borderRadius:'12px'} : {}}
                    {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div style={dropzoneMessage}>
                        {
                            loaded && <CircularProgress />
                        }
                        {
                            (!loaded && files.length === 0) &&
                            <React.Fragment>
                                <CloudUploadOutlinedIcon color='disabled' fontSize='large' />
                                {
                                    !small &&
                                    <Typography variant="body2" className="mt-2" color="textSecondary" component="p">
                                        Click or drag photo here to upload
                  </Typography>
                                }
                            </React.Fragment>
                        }
                    </div>
                </div>
                {files.length > 0 &&
                    <div className={classes.thumbsContainer} style={{ position: isDragAccept ? 'absolute' : 'relative' }}>
                        <GridList cols={1} style={{ width: '100%', minHeight: isDragAccept ? '170px' : 'initial', height: '100%' }}>
                            {thumbs}
                        </GridList>
                    </div>
                }
            </div>
            {error && <p className={classes.errorText}>{errorMsg ? errorMsg : `A ${title} is required`}</p>}
        </section>
    );
}
