import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Paper,
  Typography,
  MenuItem,
  Divider,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URI, LeadTypeEnum } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
  featureInputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    background: '#bdbfc1',
    color: 'white',
    padding: '5px 30px',

    width: 'fit-content',
    borderBottom: '2px red solid'
  }
}));

export default function DealerSettingForm({ history }) {
  const initialValues = {
    defaultDealerEmails: '',
    selectedLeadSource: '',
    dealerOverrideEmails: [],
    cmsSettings: [],
    ixSettings: [],
    carShopHubSettings: [],
    autoHubSettings: [],
    autoSlmSettings: [],
    dealersOnlineSettings: [],
    vmgSettings: [],
    overrideEmails: '',
    failedEmails: '',
    floor: '',
    source: '',
    autohubSource: '',
    dealersOnlineSource: '',
    enquiryType: '',
    dealershipID: '',
    ixDealerId: 0,
    ref: '',
    values: '',
    dealership_id: 0,
    value: '',
    leadSource: '',
    category: ''
  };

  const initialValueLeadFailed = {
    email: ''
  };

  const [formFields, setFormFields] = useState(initialValueLeadFailed);

  const { id } = useParams();
  const isAddMode = !id;
  const [loading, setLoading] = useState(true);
  const [crmSettings, setCrmSettings] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState('');

  const [selectedLeadType, setSelectedLeadType] = useState('');

  // Define the categories that have fields
  const categories = [
    { label: 'CMS', key: 'cms' },
    { label: 'AutoHub', key: 'autohub' },
    { label: 'Auto SLM', key: 'autoslm' },
    { label: 'Dealers Online', key: 'dealersOnline' },
    { label: 'VMG', key: 'vmg' },
    { label: 'IX', key: 'ix' },
    { label: 'CarShopHub', key: 'carshophub' }
  ];

  const loadCrmSettings = async () => {
    axios
      .get(`${BASE_URI}/Dealers/settings/${id}`)
      .then((result) => {
        setCrmSettings(result.data);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar('Failed to load the dealer settings. ' + error, { variant: 'error' });
      });
  };

  const loadNotificationEmail = async () => {
    axios
      .get(`${BASE_URI}/NotificationRecipient/${id}`)
      .then((result) => {
        let formData = result.data;
        setFormFields({
          ...formData
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.message);
      });
  };

  const loadSources = async () => {
    axios
      .get(`${BASE_URI}/LeadSources`)
      .then((result) => {
        setLeadSources([
          {
            id: -1,
            name: 'Please select a lead source'
          },
          {
            id: 0,
            name: 'Default'
          },
          ...result.data.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        ]);
      })
      .catch((error) => {
        setLoading(false);
        enqueueSnackbar('Failed to load the lead sources. ' + error, { variant: 'error' });
      });
  };

  useEffect(() => {
    setLoading(true);
    loadNotificationEmail();
    loadCrmSettings();
    loadSources();
  }, []);

  useEffect(() => {
    if (leadSources && leadSources.length > 0) {
      setLoading(false);
    }
  }, [crmSettings]);

  useEffect(() => {
    if (crmSettings && crmSettings.length > 0) {
      setLoading(false);
    }
  }, [leadSources]);

  const findByLeadType = (leadTypeId) => {
    if (!crmSettings) {
      return {
        ...initialValues,
        leadTypeId: leadTypeId
      };
    }

    var result = crmSettings.find((itm) => itm.leadTypeId == leadTypeId);

    if (!result) {
      return {
        ...initialValues,
        leadTypeId: leadTypeId
      };
    } else {
      return result;
    }
  };

  function onSubmitNotificationRecipient(fields, { setStatus, setSubmitting }) {
    updateNotificationRecipient(fields);
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  }

  function onSubmit(values) {
    const getItemForLeadSource = (items) => {
      var selectedLeadSourceId = values.selectedLeadSource?.id ?? -1;
      return items.find((item) => item.leadSourceId == selectedLeadSourceId);
    };

    // Reconstruct the CRM settings from the form values
    let changedCrmSettings = {
      dealerId: parseInt(id),
      leadTypeId: values.leadTypeId,
      defaultDealerEmails: values.defaultDealerEmails,
      dealerOverrideEmails: [getItemForLeadSource(values.dealerOverrideEmails)],
      cmsSettings: [getItemForLeadSource(values.cmsSettings)],
      ixSettings: [getItemForLeadSource(values.ixSettings)],
      carShopHubSettings: [getItemForLeadSource(values.carShopHubSettings)],
      autoHubSettings: [getItemForLeadSource(values.autoHubSettings)],
      autoSlmSettings: [getItemForLeadSource(values.autoSlmSettings)],
      dealersOnlineSettings: [getItemForLeadSource(values.dealersOnlineSettings)],
      vmgSettings: [getItemForLeadSource(values.vmgSettings)]
    };

    if ((values.selectedLeadSource?.id >= 0 ? values.selectedLeadSource?.id : -1) >= 0) {
      changedCrmSettings.dealerOverrideEmails[0].emails = values.overrideEmails;
      changedCrmSettings.cmsSettings[0].floor = values.floor;
      changedCrmSettings.cmsSettings[0].source = values.source;
      changedCrmSettings.cmsSettings[0].dealershipID = values.dealershipID;
      changedCrmSettings.cmsSettings[0].isMotusSSP = values.isMotusSSP;
      changedCrmSettings.carShopHubSettings[0].carShopHubDealerId = values.carShopHubDealerId;
      changedCrmSettings.autoHubSettings[0].autoHubDealerId = values.autoHubDealerId;
      changedCrmSettings.autoHubSettings[0].source = values.autohubSource;
      changedCrmSettings.autoHubSettings[0].enquiryType = values.enquiryType;
      changedCrmSettings.autoSlmSettings[0].dealerCode = values.dealerCode;
      changedCrmSettings.dealersOnlineSettings[0].dealership_id = values.dealership_id;
      changedCrmSettings.dealersOnlineSettings[0].dealersOnlineSource = values.dealersOnlineSource;
      changedCrmSettings.vmgSettings[0].vmgDealerId = values.vmgDealerId;
      changedCrmSettings.ixSettings[0].ixDealerId = values.ixDealerId;
      changedCrmSettings.ixSettings[0].ref = values.ref;
      changedCrmSettings.ixSettings[0].value = values.value;
    }

    updateDealerSetting(changedCrmSettings);
  }

  const updateDealerSetting = async (fields) => {
    axios
      .put(`${BASE_URI}/dealers/settings`, fields)
      .then((response) => {
        loadCrmSettings();
        enqueueSnackbar('Successfully updated dealer settings', { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar('Failed to save the dealer settings. ' + error, { variant: 'error' });
      });
    try {
    } catch (error) {
      enqueueSnackbar(error.data.message, { variant: 'error' });
    } finally {
      //setSubmitting(false);
    }
  };

  const updateNotificationRecipient = async (fields) => {
    fields.dealerId = id;
    axios
      .put(`${BASE_URI}/NotificationRecipient/${id}`, fields)
      .then((response) => {
        if (response) {
          enqueueSnackbar('emails added successfully', { variant: 'success' });
        }
      })
      .catch((error) => {
        enqueueSnackbar('Failed to save the Notification Recipient Emails. ' + error, { variant: 'error' });
      });
    try {
    } catch (error) {
      enqueueSnackbar(error.data.message, { variant: 'error' });
    } finally {
      //setSubmitting(false);
    }
  };

  const onLeadSourceChanged = (values, e, c) => {
    // When the lead source changed the CRM settings for that lead source need to be assigned to the form's "values" object
    let sourceId = c.props.id;
    if (sourceId == null || sourceId == undefined || sourceId < 0) return;
    values.overrideEmails = values.dealerOverrideEmails.find((itm) => itm.leadSourceId == sourceId).emails;
    values.floor = values.cmsSettings.find((itm) => itm.leadSourceId == sourceId).floor;
    values.source = values.cmsSettings.find((itm) => itm.leadSourceId == sourceId).source;
    values.isMotusSSP = values.cmsSettings.find((itm) => itm.leadSourceId == sourceId).isMotusSSP;
    values.dealershipID = values.cmsSettings.find((itm) => itm.leadSourceId == sourceId).dealershipID;
    values.vmgDealerId = values.vmgSettings.find((itm) => itm.leadSourceId == sourceId).vmgDealerId;
    values.carShopHubDealerId = values.carShopHubSettings.find((itm) => itm.leadSourceId == sourceId).carShopHubDealerId;
    values.autoHubDealerId = values.autoHubSettings.find((itm) => itm.leadSourceId == sourceId).autoHubDealerId;
    values.enquiryType = values.autoHubSettings.find((itm) => itm.leadSourceId == sourceId).enquiryType;
    values.autohubSource = values.autoHubSettings.find((itm) => itm.leadSourceId == sourceId).source;
    values.dealersOnlineSource = values.dealersOnlineSettings.find((itm) => itm.leadSourceId == sourceId).dealersOnlineSource;
    values.dealerCode = values.autoSlmSettings.find((itm) => itm.leadSourceId == sourceId).dealerCode;
    values.dealership_id = values.dealersOnlineSettings.find((itm) => itm.leadSourceId == sourceId).dealership_id;
    values.ixDealerId = values.ixSettings.find((itm) => itm.leadSourceId == sourceId).ixDealerId;
    values.ref = values.ixSettings.find((itm) => itm.leadSourceId == sourceId).ref;
    values.value = values.ixSettings.find((itm) => itm.leadSourceId == sourceId).value;
  };

  const leadSourceFiltered = leadSources.filter((ls) => ls.name === 'Website' || ls.name === 'Configurator' || ls.name === 'Facebook');

  return (
    <Paper className={classes.paper}>
      <Grid item xs={12}>
        <Typography variant='h5' component='h2' style={{ marginBottom: '16px' }}>
          Failed Notification Emails
        </Typography>
        <Formik
          initialValues={formFields}
          enableReinitialize={true}
          onSubmit={onSubmitNotificationRecipient}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .test('valid-emails', 'One or more emails are invalid', (value) => {
                if (!value) return true;
                const emails = value.split(',').map((email) => email.trim());
                return emails.every((email) => Yup.string().email().isValidSync(email));
              })
              .required('Email is required')
          })}
        >
          {({ values, touched, errors, handleBlur, handleChange, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name='email'
                    id='email'
                    label='Emails'
                    required
                    value={values.email || ''}
                    error={Boolean(errors.email && touched.email)}
                    helperText={errors.email && touched.email ? errors.email : 'Failed notification comma-separated emails'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Grid item container justifyContent='flex-end' xs={12}>
                  <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <Grid container spacing={4} direction='row'>
        <Grid item xs={12} md={6}>
          <Typography variant='h5' component='h2' style={{ marginBottom: '16px' }}>
            Configure Integration Settings
          </Typography>
          <Box mb={2}>
            <TextField
              fullWidth
              name='selectedLeadType'
              value={selectedLeadType}
              onChange={(e) => {
                setSelectedLeadType(e.target.value);
              }}
              label='Select Lead Type'
              select
            >
              {Object.entries(LeadTypeEnum).map(([key, value], index) => (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>
      </Grid>

      <Formik
        initialValues={findByLeadType(selectedLeadType)}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          onSubmit(values);
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 500);
        }}
        validationSchema={Yup.object().shape({
          // Add validations as needed
        })}
      >
        {({ values, handleChange, handleBlur, isSubmitting, errors, touched }) => (
          <Form>
            <Grid container spacing={4} direction='row'>
              {/* Left Column */}
              <Grid item xs={12} md={6}>
                {selectedLeadType !== '' && (
                  <div>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        name='defaultDealerEmails'
                        id='defaultDealerEmails'
                        label='Emails'
                        required
                        value={values.defaultDealerEmails}
                        type='text'
                        helperText={errors.defaultDealerEmails && touched.defaultDealerEmails ? errors.defaultDealerEmails : ''}
                        error={errors.defaultDealerEmails && touched.defaultDealerEmails}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>

                    <Box mb={2}>
                      <TextField
                        select
                        fullWidth
                        name='selectedLeadSource'
                        id='selectedLeadSource'
                        label='Source'
                        value={values.selectedLeadSource}
                        type='text'
                        helperText={errors.selectedLeadSource && touched.selectedLeadSource ? errors.selectedLeadSource : ''}
                        error={errors.selectedLeadSource && touched.selectedLeadSource}
                        onChange={(event, child) => {
                          onLeadSourceChanged(values, event, child);
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        variant='outlined'
                        margin='normal'
                      >
                        {leadSourceFiltered.map((option) => (
                          <MenuItem key={option.id} id={option.id} value={option}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </div>
                )}

                {/* Integration Category Dropdown (only shown if lead source is selected) */}
                {values.selectedLeadSource && (
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Typography variant='subtitle1' className={classes.title}>
                        OVERRIDE EMAILS
                      </Typography>
                    </Box>

                    <Box mb={2}>
                      <TextField
                        fullWidth
                        name='overrideEmails'
                        id='overrideEmails'
                        label='Emails'
                        value={values.overrideEmails}
                        type='text'
                        helperText={errors.overrideEmails && touched.overrideEmails ? errors.overrideEmails : ''}
                        error={Boolean(errors.overrideEmails && touched.overrideEmails)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Box>
                    <Box mb={2}>
                      <TextField
                        fullWidth
                        name='category'
                        value={values.category}
                        onChange={(e) => {
                          handleChange(e);
                          setSelectedCategory(e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='Select Integration Category'
                        select
                      >
                        <MenuItem value=''>None</MenuItem>
                        {categories.map((category) => (
                          <MenuItem key={category.key} value={category.key}>
                            {category.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>
                )}

                {/* Conditional Fields Based on Selected Lead Source */}
                <>
                  {values.category === 'cms' && (
                    <>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='dealershipID'
                          id='dealershipID'
                          label='Dealership Id'
                          value={values.dealershipID}
                          type='text'
                          helperText={errors.dealershipID && touched.dealershipID ? errors.dealershipID : ''}
                          error={errors.dealershipID && touched.dealershipID}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='source'
                          id='source'
                          label='Source'
                          value={values.source}
                          type='text'
                          helperText={errors.source && touched.source ? errors.source : ''}
                          error={errors.source && touched.source}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='floor'
                          id='floor'
                          label='Floor'
                          value={values.floor}
                          type='text'
                          helperText={errors.floor && touched.floor ? errors.floor : ''}
                          error={errors.floor && touched.floor}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>

                      <Box mb={2}>
                        <FormControlLabel
                          id='isMotusSSP'
                          name='isMotusSSP'
                          checked={values.isMotusSSP}
                          control={<Checkbox name={values.isMotusSSP} color='primary' onChange={handleChange('isMotusSSP')} />}
                          label={'Motus SSP ?'}
                        />
                      </Box>
                    </>
                  )}

                  {values.category === 'autohub' && (
                    <>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='autoHubDealerId'
                          id='autoHubDealerId'
                          label='Auto hub dealer Id'
                          value={values.autoHubDealerId}
                          type='number'
                          onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                          helperText={errors.autoHubDealerId && touched.autoHubDealerId ? errors.autoHubDealerId : ''}
                          error={errors.autoHubDealerId && touched.autoHubDealerId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='autohubSource'
                          id='autohubSource'
                          label='Source'
                          value={values.autohubSource}
                          type='text'
                          helperText={errors.autohubSource && touched.autohubSource ? errors.autohubSource : ''}
                          error={errors.autohubSource && touched.autohubSource}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='enquiryType'
                          id='enquiryType'
                          label='Enquiry Type'
                          value={values.enquiryType}
                          type='text'
                          helperText={errors.enquiryType && touched.enquiryType ? errors.enquiryType : ''}
                          error={errors.enquiryType && touched.enquiryType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </>
                  )}

                  {values.category === 'autoslm' && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name='dealerCode'
                        id='dealerCode'
                        label='Auto SLM Dealer Code'
                        value={values.dealerCode || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.dealerCode && touched.dealerCode}
                        helperText={errors.dealerCode && touched.dealerCode ? errors.dealerCode : ''}
                        variant='outlined'
                      />
                    </Grid>
                  )}

                  {values.category === 'dealersOnline' && (
                    <>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='dealership_id'
                          id='dealership_id'
                          label='Dealership id'
                          value={values.dealership_id}
                          type='number'
                          onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                          helperText={errors.dealership_id && touched.dealership_id ? errors.dealership_id : ''}
                          error={errors.dealership_id && touched.dealership_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='dealersOnlineSource'
                          id='dealersOnlineSource'
                          label='Source'
                          value={values.dealersOnlineSource}
                          type='text'
                          helperText={errors.dealersOnlineSource && touched.dealersOnlineSource ? errors.dealersOnlineSource : ''}
                          error={errors.dealersOnlineSource && touched.dealersOnlineSource}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </>
                  )}

                  {values.category === 'vmg' && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name='vmgDealerId'
                        label='VMG Dealer ID'
                        value={values.vmgDealerId || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant='outlined'
                      />
                    </Grid>
                  )}

                  {values.category === 'ix' && (
                    <>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='ixDealerId'
                          id='ixDealerId'
                          label='Dealer Id'
                          value={values.ixDealerId}
                          type='number'
                          onKeyDown={(e) => {
                            if (e.key === '-' || e.key === 'e') {
                              e.preventDefault();
                            }
                          }}
                          helperText={errors.ixDealerId && touched.ixDealerId ? errors.ixDealerId : ''}
                          error={errors.ixDealerId && touched.ixDealerId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='ref'
                          id='ref'
                          label='Ref'
                          value={values.ref}
                          type='text'
                          helperText={errors.ref && touched.ref ? errors.ref : ''}
                          error={errors.ref && touched.ref}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>

                      <Box mb={2}>
                        <TextField
                          fullWidth
                          name='value'
                          id='value'
                          label='Value'
                          value={values.value}
                          type='text'
                          helperText={errors.value && touched.value ? errors.value : ''}
                          error={errors.value && touched.value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Box>
                    </>
                  )}

                  {values.category === 'carshophub' && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name='carShopHubId'
                        id='carShopHubId'
                        label='Car Shop Hub ID'
                        value={values.carShopHubId || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.carShopHubId && touched.carShopHubId}
                        helperText={errors.carShopHubId && touched.carShopHubId ? errors.carShopHubId : ''}
                        variant='outlined'
                      />
                    </Grid>
                  )}
                </>
              </Grid>
              {
                selectedLeadType!=='' &&
                <Grid item container justifyContent='flex-end' xs={12} style={{ marginTop: '16px' }}>
                <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                  Save
                </Button>
              </Grid>
              }
              
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}
