import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';

const useIntersectionObserver = ({
                                   target,
                                   onIntersect,
                                   threshold = 0.1,
                                   rootMargin = "0px"
                                 }) => {
  React.useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      rootMargin,
      threshold
    });
    const current = target.current;
    observer.observe(current);
    return () => {
      observer.unobserve(current);
    };
  });
};

const useStyles = makeStyles((theme) => ({
  mediaContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  }
}));

const isVideoFormat = (src) => {
  const videoExtensions = ['mp4', 'webm', 'ogg', 'mov'];
  const extension = src.split('.').pop().toLowerCase();
  return videoExtensions.includes(extension);
};

export const ImageContainer = props => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);
  const classes = useStyles();

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    }
  });

  return (
    <div
      ref={ref}
      className={classes.mediaContainer}>
      {isVisible && (
        isVideoFormat(props.src) ? (
          <video
            controls
            style={{ width: '100%', height: 'auto' }}
          >
            <source src={props.src} type={`video/${props.src.split('.').pop()}`} />
            Your browser does not support the video tag.
          </video>
        ) : (
          <Image
            src={props.src}
            alt={props.alt}
            imageStyle={{
              height: 'initial',
              bottom: 0,
              margin: 'auto'
            }}
            aspectRatio={(16 / 9)}
            color='lightgrey'
          />
        )
      )}
    </div>
  );
};
