import React, { useEffect, useState, useContext } from "react";
import { Grid, TextField, Button, CardHeader, Divider,
     MenuItem, Select, Checkbox,OutlinedInput,ListItemText
     , Chip,Box,CardContent,Card,InputLabel   } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from 'yup'
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { BASE_URI, DEFAULT_PAGE_SIZE, PHONE_REGEX } from "../../shared/Constants";
import { useSnackbar } from 'notistack';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ImageUploadInput from '../../shared/ImageUploadFormInput'
import CategoryFilter from "../categories/CategoryFilter";
import { DealerContext } from "../../shared/DealerContext";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiCardHeader-title": {
            fontSize: "20px",
        },
    },
    flexCenter: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        display: 'inline-block',
        width: '100%'
    },
    stepPaddingButtons: {
        marginTop: "1rem",
        marginLeft: "2rem",
        marginRight: "2rem",
    },
    modalContainer: {
        justifyContent: 'center',
        display: 'flex',
        height: 'fit-content',
        textAlign: 'center',
        marginTop: '150px',
    },
    modalContent: {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '3%',
        borderRadius: '10px'
    },
    modalButtons: {

    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    paperHeading: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        marginBottom: '1rem',
        height: 'fit-content',
        paddingBottom: '0'
    },
    errorText: {
        fontSize: "0.75rem",
        marginTop: "3px",
        textAlign: "left",
        fontWeight: 400,
        lineHeight: "1.66",
        letterSpacing: "0.03333em",
        color: "#f44336",
    },
    featureInputContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
}));

export default function AdminManufacturers() {
    const initialValues = {
        primaryColor: '',
        secondaryColor: '',
        accentColor: '',
        logo: '',
        logoAltTag: '',
        heroImage: '',
        heroAltTag: '',
        roadSideAssistTel: '',
    }
    const { id } = useParams();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [activeMake, setActiveMake] = useState(null);
    const [makes, setMakes] = useState([]);
    const [formFields, setFormFields] = useState(initialValues);
    const [categoryIds, setCategoryIds] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('name');
    const [searchString, setSearchString] = useState('');
    const [searchInput, setSearchInput] = useState('');

    const { categories, total } = CategoryFilter(searchString, page, pageSize, order, orderBy, true);

    let makeCats = categories.filter((cat) => cat.type.toLowerCase() === 'make');
    const { dealerId } = useContext(DealerContext);

    const preventDefault = (event) => { event.preventDefault() };
    const { enqueueSnackbar } = useSnackbar();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    const handleChangebaseColor = (value) => {
        setFormFields({ ...formFields, primaryColor: value });
    }

    const handleChangesecondaryColor = (value) => {
        setFormFields({ ...formFields, secondaryColor: value });
    }
    const handleChangeaccentColor = (value) => {
        setFormFields({ ...formFields, accentColor: value });
    }
    const handleLogoAltTagText = (value) => {
        setFormFields({ ...formFields, logoAltTag: value });
    }

    const handleHeroAltTagText = (value) => {
        setFormFields({ ...formFields, heroAltTag: value });
    }

    useEffect(() => {
        const getMake = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/makes`)
                setMakes(result.data.list)
            } catch (error) {
                enqueueSnackbar(`Unable to retrieve vehicle makes`, { variant: 'error' });
            }
        }
        getMake();

    }, []);

    const getMakeCategories = async (makeId) => {
        try {
          const result = await axios.get(`${BASE_URI}/Categories/make/${makeId}`);
          if (result && result.data) {
            // Clear previous category IDs
            const catIds = result.data.map(cat => cat.id);
            setCategoryIds(catIds);
          }
        } catch (error) {
          console.error(error.message);
        }
      };
      
    useEffect(() => {
        if (activeMake) {
            setFormFields({
                ...formFields,
                id: activeMake?.id,
                name: activeMake?.name,
                logo: activeMake?.logo,
                heroImage: activeMake?.heroImage,
                primaryColor: activeMake?.primaryColor,
                secondaryColor: activeMake?.secondaryColor,
                accentColor: activeMake?.accentColor,
                roadSideAssistTel: activeMake?.roadSideAssistTel,
                logoAltTag: activeMake?.logoAltTag,
                heroAltTag: activeMake?.heroAltTag,
                category: categoryIds
            })
        }
    }, [activeMake])

    const setImageUrl = (fieldName, url) => {
        setFormFields({
            ...formFields,
            [`${fieldName}`]: url,
        })
    }

    function SubmitChanges(values) {
        const update = async () => {
            setLoading(true);
            try {
                const logo = values?.logo;
                values.logo = logo?.image || logo;

                const fileName = logo?.fileName;
                values.fileName = fileName;

                //hero image and name
                const heroImage = values?.heroImage;
                values.heroImage = heroImage?.image || heroImage;

                const heroImageFileName = heroImage?.fileName;
                values.heroImageFileName = heroImageFileName;

                const result = await axios.put(`${BASE_URI}/makes/${activeMake?.id}`, {
                    ...values,
                    roadSideAssistTel: `${values?.roadSideAssistTel}`
                })

                if (result?.data != null) {
                    const getMake = async () => {
                        enqueueSnackbar(`Manufaturer settings updated`, { variant: 'success' });
                        try {
                            const result = await axios.get(`${BASE_URI}/makes`)
                            setMakes(result.data.list)
                        } catch (error) {
                            enqueueSnackbar(`Unable to retrieve vehicle Manufaturers `, { variant: 'error' });
                        }
                    }
                    getMake();
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        update();
    }

    const handleCategoryChange = (event, setFieldValue) => {
        setFieldValue('category', event.target.value);
        setCategoryIds(event.target.value);
      };

    return (
        <Card className="d-flex justify-content-between flex-column">
            <CardHeader title={'Manufaturers Settings'} />
            <CardContent style={{ width: '100%', display: 'block' }}>
                <React.Fragment>
                    <Grid item className={classes.stepPadding}>
                    <Autocomplete
                        id="allMakes"
                        name="allMakes"
                        value={activeMake}
                        options={makes}
                        disabled={dealerId === '' || dealerId === undefined}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                            if (value) {
                            setActiveMake(value);
                            if (value.id) {
                                getMakeCategories(value.id);
                            }
                            } else {
                            setActiveMake(null); 
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Select Make"
                            />
                        )}
                        />

                    </Grid>
                </React.Fragment>
                <Divider />
                {formFields?.id && <React.Fragment>
                    <Formik
                        initialValues={formFields}
                        onSubmit={SubmitChanges}
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                            roadSideAssistTel: Yup.string().matches(PHONE_REGEX, 'Mobile number is not valid').notRequired()
                        })}
                    >
                        {(props) => {
                            const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset,setFieldValue } = props;
                            return (
                                <Form>
                                    <Grid container spacing={4} className={classes.stepPadding} direction="column">
                                        <Grid item xs={12} md={6}>

                                            <Grid item xs={12}>

                                                <Typography variant="h6" gutterBottom>
                                                    {`${'Make COLORS'} (${activeMake?.name})`}                                                                                                                                                                                                                
                                                </Typography>
                                            </Grid>
                                            <Typography variant="h6" gutterBottom>
                                                Contact
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                name="roadSideAssistTel"
                                                id="roadSideAssistTel"
                                                label="Raodside Assist Telephone"
                                                value={values.roadSideAssistTel}
                                                type="number"
                                                helperText={errors.roadSideAssistTel && touched.roadSideAssistTel ? errors.roadSideAssistTel : ''}
                                                error={errors.roadSideAssistTel && touched.roadSideAssistTel}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                                <InputLabel id="category">Categories</InputLabel>
                                                <Select
                                                    labelId="category"
                                                    id="category"
                                                    multiple
                                                    fullWidth
                                                    value={categoryIds}
                                                    onChange={(e,child)=>handleCategoryChange(e,setFieldValue)}
                                                    input={<OutlinedInput id="category" label="Categories" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                          {selected !== null && Array.isArray(selected) && selected.map((value) => (
                                                            <Chip key={value} label={makeCats.find((cat) => cat.id === value)?.name} />
                                                          ))}
                                                        </Box>
                                                      )}
                                                    MenuProps={MenuProps}>
                                                   {makeCats.map((option) => (
                                                    <MenuItem key={option.name} value={option.id}>
                                                    <Checkbox checked={categoryIds.indexOf(option.id) > -1} />
                                                    <ListItemText primary={option.name} />
                                                    </MenuItem>
                                                ))}
                                                </Select>
                                            <Typography variant="h6" gutterBottom>
                                                Hex
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                name="primaryColor"
                                                //required
                                                id="primaryColor"
                                                label="Primary Color"
                                                type="text"
                                                value={`${formFields?.primaryColor}`}
                                                onChange={(e) => handleChangebaseColor(e.target.value)}
                                            >

                                            </TextField>
                                            <Typography variant="h6" gutterBottom>
                                                Hex
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                name="secondaryColor"
                                                //required
                                                id="secondaryColor"
                                                label="Secondary Color"
                                                type="text"
                                                value={`${formFields?.secondaryColor}`}
                                                onChange={(e) => handleChangesecondaryColor(e.target.value)}
                                            >
                                            </TextField>
                                            <Typography variant="h6" gutterBottom>
                                                Hex
                                            </Typography>
                                            <TextField
                                                fullWidth
                                                name="accentColor"
                                                //required
                                                id="accentColor"
                                                label="Accent Color"
                                                type="text"
                                                value={`${formFields?.accentColor}`}
                                                onChange={(e) => handleChangeaccentColor(e.target.value)}
                                            >
                                            </TextField>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ImageUploadInput title='Logo'
                                                fieldName={'logo'}
                                                value={values.logo}
                                                error={errors.logo && touched.logo}
                                                setImage={setImageUrl} />
                                                <br></br><br></br>
                                                <TextField
                                                fullWidth
                                                name="logoAltTag"
                                                id="logoAltTag"
                                                label="Alt Tag"
                                                type="text"
                                                value={`${formFields?.logoAltTag}`}
                                                onChange={(e) => handleLogoAltTagText(e.target.value)}
                                                helperText={errors.logoAltTag && touched.logoAltTag ? errors.logoAltTag : "Alt tag for SEO"}
                                                error={errors.logoAltTag && touched.logoAltTag}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ImageUploadInput title='Hero Image'
                                                fieldName={'heroImage'}
                                                value={values.heroImage}
                                                error={errors.heroImage && touched.heroImage}
                                                setImage={setImageUrl} />
                                                <TextField
                                                    fullWidth
                                                    name="heroAltTag"
                                                    id="heroAltTag"
                                                    label="Alt Tag"
                                                    type="text"
                                                    value={`${formFields?.heroAltTag}`}
                                                    onChange={(e) => handleHeroAltTagText(e.target.value)}
                                                    helperText={errors.heroAltTag && touched.heroAltTag ? errors.heroAltTag : "Alt tag for SEO"}
                                                    error={errors.heroAltTag && touched.heroAltTag}
                                                />
                                        </Grid>

                                        <Grid item container
                                            justify='flex-end' xs={12}>

                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                            >
                                                Save
                                            </Button>
                                        </Grid>


                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }
                        }

                    </Formik>
                </React.Fragment>}
            </CardContent>
        </Card>
    );
}
