import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Grid from '@material-ui/core/Grid';
import RangeListContainer from './range/RangeListContainer';
import Paper from '@material-ui/core/Paper';
import DerivativeListContainer from './derivative/DerivativeListContainer';
import ColourTabs from './colour/ColourTabs';
import AccessoryListContainer from './accessories/AccessoryListContainer';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ReactComponent as CarTag } from '../../shared/assets/icons/car_tag.svg';
import { ReactComponent as Colors } from '../../shared/assets/icons/colors.svg';
import { ReactComponent as DirectionsCar } from '../../shared/assets/icons/directions_car.svg';
import { ReactComponent as SpatialSpeaker } from '../../shared/assets/icons/spatial_speaker.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  completed: {
    display: 'inline-block'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },

  stepContainer: {
    borderRadius: '10px',
    background: 'rgba(171, 189, 211, 0.10)',
    marginBottom: '30px'
  }
}));

function getSteps() {
  return ['Models', 'Variants', 'Colours', 'Accessories'];
}

export default function DASHContainer() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const { step, modelId, dealerModelId, dealerVariantId } = useParams();
  const [editingVariantId, setEditingVariantId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();

  useEffect(() => {
    if (step) {
      switch (step) {
        case 'models':
          return setActiveStep(0);
        case 'variants':
          return setActiveStep(1);
        case 'colours':
          return setActiveStep(2);
        case 'accessories':
          return setActiveStep(3);
        default:
          return setActiveStep(0);
      }
    } else {
      setActiveStep(0);
    }
  }, [step]);

  const selectDealerVariant = (id) => {
    setEditingVariantId(id);
  };

  const validateStep = (route, modelId, dealerModelId, dealerVariantId) => {
    switch (route) {
      case 'models': {
        // No validation necessary
        return '';
      }
      case 'variants': {
        // Need the modelId & the dealerModelId
        if (!modelId || !dealerModelId) {
          return 'Cannot proceed to this step: no model selected';
        } else {
          return '';
        }
      }
      case 'colours':
      case 'accessories': {
        // Need the modelId, dealerModelId and the dealerVariantId
        if (!modelId || !dealerModelId || !dealerVariantId) {
          return 'Cannot proceed to this step: no variant selected';
        } else {
          return '';
        }
      }
    }
  };

  const redirectRoute = (route, modelId, dealerModelId, dealerVariantId) => {
    switch (route) {
      case 'models':
        return `/DASH/${route}`;
      case 'variants':
        return `/DASH/${route}/${modelId}/${dealerModelId}`;
      case 'colours':
        return `/DASH/${route}/${modelId}/${dealerModelId}/${dealerVariantId}`;
      case 'accessories':
        return `/DASH/${route}/${modelId}/${dealerModelId}/${dealerVariantId}`;
      default:
        return `/DASH`;
    }
  };

  const handleNext = (route, modelId, dealerModelId, dealerVariantId) => {
    var invalidMessage = validateStep(route, modelId, dealerModelId, dealerVariantId);

    if (invalidMessage.length > 0) {
      enqueueSnackbar(invalidMessage, { variant: 'warning', vertical: 'top', horizontal: 'center' });
      return;
    }

    setEditingVariantId(null);

    return history.push(redirectRoute(route, modelId, dealerModelId, dealerVariantId));
  };

  function getStepContent() {
    if (step && step.toLowerCase() != steps[activeStep].toLowerCase()) return;

    switch (activeStep) {
      case 0:
        return <RangeListContainer navigate={handleNext} />;
      case 1:
        return (
          <DerivativeListContainer
            navigate={handleNext}
            selectVariant={selectDealerVariant}
            modelId={modelId}
            dealerModelId={dealerModelId}
          />
        );
      case 2:
        return <ColourTabs navigate={handleNext} modelId={modelId} dealerModelId={dealerModelId} dealerVariantId={dealerVariantId} />;
      case 3:
        return (
          <AccessoryListContainer navigate={handleNext} modelId={modelId} dealerModelId={dealerModelId} dealerVariantId={dealerVariantId} />
        );
      default:
        return 'Unknown step';
    }
  }

  const handleStep = (step) => () => {
    var route = steps[step].toLowerCase();

    handleNext(route, modelId, dealerModelId, dealerVariantId || editingVariantId);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  const getIconStyle = (isSelected) => ({
    fill: isSelected ? '#FFFFFF' : '#012F56',
    width: '24px',
    height: '24px'
  });

  const getCircleStyle = (isSelected) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: isSelected ? '#012F56' : 'rgba(171, 189, 211, 0.80)',
    borderRadius: '50%',
    padding: '12px',
    position: 'relative',
    zIndex: 1
  });

  const icons = steps.map((label, index) => (
    <div style={getCircleStyle(activeStep === index)}>
      {index === 0 && <CarTag style={getIconStyle(activeStep === index)} />}
      {index === 1 && <DirectionsCar style={getIconStyle(activeStep === index)} />}
      {index === 2 && <Colors style={getIconStyle(activeStep === index)} />}
      {index === 3 && <SpatialSpeaker style={getIconStyle(activeStep === index)} />}
    </div>
  ));

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear className={classes.stepContainer} activeStep={activeStep} style={{ position: 'relative' }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepButton
                icon={icons[index]}
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '14px'
                }}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction='column'>
          <Grid item xs={12}>
            {getStepContent()}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
